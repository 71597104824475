<template>
  <login />
</template>

<script>
import Login from "../components/login/Login.vue";
export default {
  components: { Login },
};
</script>
